
































import Vue from "vue";
import MemberModal from './MemberModal.vue';
import Modal from './Modal.vue';
import ModalMixin from './mixins/ModalMixin';
import ModalService from "./services/ModalService.vue";

export default Vue.extend({
  name: "InstantMessageModal",
  components: {Modal},
  props: ["user"],
  data: () => {
    return {
      userData: null,
      view: "respond",
      message: "",
    };
  },
  methods: {
    openMemberModal(){
      ModalService.open(MemberModal, {
        user: this.user
      });
    },
    reply(){
      this.userData = {username: "test"};
    },
    sendMessage(){
      // TO DO
      // Add functionality and emit to server.
      ModalService.open(MemberModal, {
        user: this.user,
        status: "success"
      })
    },
  },
  mounted(){
    if(this.user){
      this.userData = this.user;
    }
  },
  mixins: [ModalMixin],
});
