






































































import Vue from "vue";
import CitizenOnlineModal from './CitizenOnlineModal.vue';
import Modal from './Modal.vue';
import ModalMixin from './mixins/ModalMixin';
import ModalService from "./services/ModalService.vue";

export default Vue.extend({
  name: "ConfigureModal",
  components: {Modal},
  data: () => {
    return {
      visible: true,
      buddyList: ["", "", "", "", "", "", "", "", "", "",],
      blockedList: ["", "", "", "", "", "", "", "", "", "",],
      view: "main",
    };
  },
  methods: {
    openCitizenOnlineList(){
      ModalService.open(CitizenOnlineModal);
    },
    openBuddiesList(){
      // TO DO
      // Pull buddy list and populate the array.
      this.view = "buddies";
    },
    openBlockedList(){
      // TO DO
      // Pull blocked list and populate the array.
      this.view = "blocked";
    },
    openMain(){
      this.buddyList = ["", "", "", "", "", "", "", "", "", "",];
      this.blockedList = ["", "", "", "", "", "", "", "", "", "",];
      this.view = "main";
    },
    saveBuddyList(){
      // TO DO
      // Save list in the DB
      this.openMain();
    },
    saveBlockedList(){
      // TO DO
      // Save list in the DB
      this.openMain();
    },
    changeMode(){
      // TO DO
      // Save status in DB.
    }
  },
  mounted(){

  },
  mixins: [ModalMixin],
});
