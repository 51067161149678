


























































































import Vue from "vue";

export default Vue.extend({
  name: "UserBanHistory",
  data() {
    return {
      info: [],
      success: null,
      showDeleteModal: false,
      banId: 0,
      banReason: "",
    };
  },
  props: [
      "accessLevel",
  ],
  methods:{
    async getinfo(): Promise <void>{
      await this.$http.get("/admin/banhistory/", {
        ban_member_id: this.$route.params.id,
      })
        .then((response) => {
          this.info = response.data.banHistory;
        });
    },
    async deleteban(): Promise<void>{
      await this.$http.post("/admin/deleteban/", {
        banId: this.banId,
        banReason: this.banReason,
      })
        .then(() => {
          this.showDeleteModal = false;
          this.success = "Ban Deleted";
          this.getinfo();
        });
    },
  },
  async mounted() {
    if (!this.accessLevel) {
      this.$router.push({ name: "restrictedaccess" });
    }
    await this.getinfo();
  },
});
