
































import Vue from "vue";

export default Vue.extend({
  name: "UserStorage",
  props: ["member_id"],
  data: () => ({
    username: undefined,
      unitName: null,
      unitId: null,
      units: [],
      showError: false,
      error: "",
      showSuccess: false,
      current: null,
  }),
  methods: {
    async getUnits(){
      this.username = this.$store.data.user.username;
      this.units = [];
      try{
        const storageUnits = await this.$http.post(`/member/storage`, {
          member_id: this.member_id,
        });
        storageUnits.data.storage.forEach(unit => {
          this.units.push(unit);
        });
      } catch (errorResponse: any) {
        console.error(errorResponse);
      }
    },
    async addStorage(){
      let newStorage;
      if(this.units.length < 10){
        newStorage = "Area0" + this.units.length;
      } else {
        newStorage = "Area" + this.units.length;
      } 
      try {
        await this.$http.post("/place/add_storage", {name: newStorage})
      } catch (error) {
        console.error(error);
      } finally {
        await this.getUnits();
      }
    },
    storageOpener(id) {  
      window.open("/#/storage/unit/"+id, "targetWindow", "width=1000px,height=700px,location=0,menubar=0,status=0,scrollbars=0");
    },
    updateStorageAreas() {
      window.open("/#/storage/update", "targetWindow", "width=1000px,height=700px,location=0,menubar=0,status=0,scrollbars=0");
    },
  },
  mounted() {
    this.getUnits();
    this.current = this.$route.path;
  }
});
