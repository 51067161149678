











































































































import Vue from "vue";
export default Vue.extend({
  name: "AdminObjectUpdate",
  data: () => {
    return {
      id: null,
      name: null,
      directory: null,
      filename: null,
      thumbnail: null,
      texture: null,
      creator: null,
      price: null,
      limit: null,
      quantity: null,
      sold: null,
      status: null,
      imgFile: null,
      newName: null,
      newDirectory: null,
      newFilename: null,
      newThumbnail: null,
      newPrice: null,
      newLimit: null,
      newQuantity: null,
      newStatus: null,
      objectState: ["Rejected", "Stocked", "Pending", "Accepted", "Destocked"],
      accessLevel: "none",
      details: [],
      newDetails: [],
      error: "",
      success: "",
    };
  },
  methods: {
    async getAdminLevel(): Promise<void> {
      try{
        await this.$http.get("/member/getadminlevel")
          .then((response) => {
            this.accessLevel = response.data.accessLevel;
            if (this.accessLevel === "none"){
              this.$router.push({name: "restrictedaccess"});
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    async objectDetails() {
      const object = await this.$http.get(`/mall/getObject/${this.$route.params.id}`);
      this.id = object.data.object.id;
      this.name = object.data.object.name;
      this.directory = object.data.object.directory;
      this.filename = object.data.object.filename;
      this.thumbnail = object.data.object.image;
      this.texture = object.data.object.texture;
      this.creator = object.data.username;
      this.price = object.data.object.price;
      this.limit = object.data.object.limit;
      this.quantity = object.data.object.quantity;
      this.sold = object.data.object.instances;
      this.status = object.data.object.status;
      this.imgFile = `/assets/object/${this.directory}/${this.thumbnail}`;
      this.newName = this.name;
      this.newDirectory = this.directory;
      this.newFilename = this.filename;
      this.newThumbnail = this.thumbnail;
      this.newPrice = this.price;
      this.newLimit = this.limit;
      this.newQuantity = this.quantity;
      this.newStatus = this.status;
    },
    async updateObject() {
      this.error = "";
      this.success = "";
      this.details = [];
      this.newDetails = [];
      this.details = [
        this.name, this.directory, this.filename, 
        this.thumbnail, this.price, this.limit, 
        this.quantity, this.status];
      this.newDetails = [
        this.newName, this.newDirectory, this.newFilename, 
        this.newThumbnail, this.newPrice, this.newLimit, 
        this.newQuantity, this.newStatus];
      if(JSON.stringify(this.details) !== JSON.stringify(this.newDetails)){
        if((this.directory !== this.newDirectory &&
          this.filename !== this.newFilename &&
          this.thumbnail !== this.newThumbnail) || 
          (this.directory === this.newDirectory &&
          this.filename === this.newFilename &&
          this.thumbnail === this.newThumbnail)){
            try{
              await this.$http.post(`/admin/objects/update`, {
                id: this.id,
                name: this.newName,
                directory: this.newDirectory,
                filename: this.newFilename,
                thumbnail: this.newThumbnail,
                price: this.newPrice,
                limit: this.newLimit,
                quantity: this.newQuantity,
                status: this.newStatus,
              });
              this.success = "Update Successful!"
              this.objectDetails();
            } catch(error) {
              this.error = "Update Failed!";
            }
        } else {
          this.error = "Must change the directory, filename, and thumbnail when fixing the objects files.";
        }
      }
    },
  },
  created() {
    this.getAdminLevel();
  },
  mounted() {
    this.objectDetails();
  }
})
