






















































































































import Vue from "vue";

import {
  debugMsg,
} from "@/helpers";
import {response} from "express";

export default Vue.extend({
  name: "MessageBoard",
  data: () => {
    return {
      active: "view",
      boardadmin: false,
      body: "",
      ddate: "",
      dfrom: "",
      did: 0,
      display: false,
      dmessage: "",
      dparentid: "",
      dreply: "",
      dsubject: "",
      error: "",
      intro: "",
      messageboardmessages: [],
      placeinfo: [],
      subject: "",
      success: "",
    };
  },
  methods: {
    //manage introduction information for message board
    async changeMessageboardIntro(): Promise<void> {
      try {
        await this.$http.post("/messageboard/changemessageboardintro", {
          place_id: this.$route.params.place_id,
          intro: this.intro,
          type: this.placeinfo[0].type,
        });
        this.success = "Message Board Information Updated";
        this.error = "";
        this.display = false;
      } catch (error) {
        this.error = error.response.data.error;
        this.success = "";
      } finally {
        this.getMessageboardMessages();
        this.getInfo();
        this.active = "view";
      }
      
    },
    //delete a specific message
    async deleteMessageboardMessage(): Promise<void> {
      try {
        const {data} = await this.$http.post("/messageboard/deletemessage/", {
          place_id: this.$route.params.place_id,
          message_id: this.did,
          type: this.placeinfo[0].type,
        });
        this.success = "Message Deleted";
        this.error = "";
        this.display = false;
      } catch (error) {
        this.error = error.response.data.error;
        this.success = "";
      } finally {
        this.getMessageboardMessages();
      }
      
    },
    //get admin info from db and/or check if user is owner of message board
    async getAdminInfo(): Promise<any> {
      return this.$http.post("/messageboard/getadmininfo", {
        place_id: this.$route.params.place_id,
        type: this.placeinfo[0].type,
      }).then((response) => {
        this.boardadmin = response.data.admin;
      });
    },
    
    //get message board introduction information
    async getInfo(): Promise<void> {
      return this.$http.post("/messageboard/info/", {
        place_id: this.$route.params.place_id,
      }).then((response) => {
        this.placeinfo = response.data.placeinfo;
      });
    },
    //get and prepares specific information for displaying in lower div
    async getMessage(
      id: number,
      date: string,
      user: string,
      subject: string,
      parentid: string,
      reply: string,
    ): Promise<void> {
      return this.$http.post("/messageboard/getmessage/", {
        message_id: id,
      }).then((response) => {
        this.dmessage = response.data.getmessage;
        this.ddate = date;
        this.dfrom = user;
        this.dsubject = subject;
        this.did = id;
        this.dparentid = parentid;
        this.dreply = reply;
        this.display = true;
      });
    },
    //gets all messages that are active on message board
    async getMessageboardMessages(): Promise<void> {
      return this.$http.post("/messageboard/messages/", {
        place_id: this.$route.params.place_id,
      }).then((response) => {
        this.messageboardmessages = response.data.messageboardmessages;
      });
    },
    //post a message to the message board
    async postMessageboardMessage(): Promise<void> {
      try {
        const {data} = await this.$http.post("/messageboard/postmessage", {
          place_id: this.$route.params.place_id,
          subject: this.subject,
          body: this.body,
        });
        this.success = "Message was posted";
        this.error = "";
        this.active = "view";
        this.subject = "";
        this.body = "";
        this.getMessageboardMessages();
      } catch (error) {
        this.error = error.response.data.error;
        //this.body = error.response.data.error.body;
        this.success = "";
      }
    },
    //post a reply to message board
    async postMessageboardReply(): Promise<void> {
      try {
        const {data} = await this.$http.post("/messageboard/postreply", {
          place_id: this.$route.params.place_id,
          subject: this.dsubject,
          body: this.body,
          parent_id: this.dparentid,
        });
        this.success = "Reply was posted";
        this.error = "";
        this.active = "view";
        this.subject = "";
        this.body = "";
      } catch (error) {
        this.error = "Unauthorized HTML Tag Used. The tag(s) have been removed, hit post again to send";
        this.body = "testing something";
        this.success = "";
      }
    },
    //button action for manage
    switchManage(): void {
      this.intro = this.placeinfo[0].messageboard_intro;
      this.active = "manage";
    },
    //button action for post
    switchPost(): void {
      this.active = "post";
    },
    //button action for reply
    switchReply(): void {
      this.active = "reply";
    },
    //button action for message view
    switchView(): void {
      this.body = "";
      this.subject = "";
      this.display = false;
      this.error = "";
      this.success = "";
      this.active = "view";
    },
  },
  async created() {
    await this.getInfo();
    await this.getMessageboardMessages();
    await this.getAdminInfo();
  },
  watch: {
    active: function(newValue) {
      if (newValue === "view") {
        this.getMessageboardMessages();
      }
    },
  },
});
