























































import Vue from "vue";

import Modal from './Modal.vue';
import ModalMixin from './mixins/ModalMixin';
import AvatarModal from "./AvatarModal.vue";
import AccountModal from "./AccountModal.vue";
import ModalService from "./services/ModalService.vue";
import PersonalInfoModalVue from './PersonalInfoModal.vue';

export default Vue.extend({
  name: "InfoModal",
  components: {Modal},
  methods: {
    openAvatarModal(): void {
      ModalService.open(AvatarModal);
    },
    openAccountModal(): void {
      ModalService.open(AccountModal);
    },
    openPersonalInfoModal(): void {
      ModalService.open(PersonalInfoModalVue);
    },
  },
  mixins: [ModalMixin],
});
