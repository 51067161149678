
























































































import Vue from "vue";

export default Vue.extend({
  name: "PlaceSearch",
  data: () => {
    return {
      totalCount: 0,
      places: [],
      type: 'colony',
      limit: 10,
      offset: 0,
      pageNum: 1,
      pages: [],
      compare: '=',
      search: '',
      showNext: true,
      error: null,
      status: ['Disabled','Active'],
    };
  },
  props: [
    "accessLevel",
  ],
  methods: {
    async searchPlaces(): Promise<any> {
      this.places = [];
      this.pages = [];
      try {
        const searched =  await this.$http.get("/admin/allplacessearch/", {
            limit: this.limit,
            offset: this.offset,
            search: this.search,
            compare: this.compare,
            type: this.type
          });
          this.places = searched.data.results.places;
          this.totalCount = searched.data.results.total[0].count;
          let pages = Math.ceil(this.totalCount/this.limit);
          for(let i = 1; pages >= i; i++){
            this.pages.push(i);
          }
          if(this.pageNum > pages && this.totalCount > 0){
            this.pageNum = 1;
            this.offset = 0;
            setTimeout(this.searchPlaces, 1000);
          }
      } catch (error) {
        console.log(error);
      }
    },
    async updateStatus(id, state){
      let updateStatus;
      if(state === 0){
        updateStatus = 1;
      } else {
        updateStatus = 0;
      }
      try{
        await this.$http.post("/admin/places/update/", {
          id: id,
          column: 'status',
          content: updateStatus
        }).then(response => {
          if(response.data.status === 'success'){
            this.searchPlaces();
          }
        })
      } catch(error){
        console.log(error);
      }
    },
    async updateName(id, name){
      let newName = prompt("Current Name:\n " + name + "\n\nNew Name:", name);
      if(newName !== null && newName !==''){
        try{
        await this.$http.post("/admin/places/update/", {
          id: id,
          column: 'name',
          content: newName
        }).then(response => {
          if(response.data.status === 'success'){
            this.searchPlaces();
          }
        })
      } catch(error){
        console.log(error);
      }
      }
    },
    async updateDesc(id, desc){
      let newDesc = prompt("Current Description:\n " + desc + "\n\nNew Description:", desc);
      if(newDesc !== null && newDesc !==''){
        try{
        await this.$http.post("/admin/places/update/", {
          id: id,
          column: 'description',
          content: newDesc
        }).then(response => {
          if(response.data.status === 'success'){
            this.searchPlaces();
          }
        })
      } catch(error){
        console.log(error);
      }
      }
    },
    setLimit(){
      this.offset = 0;
      this.pageNum = 1;
      this.searchPlaces();
    },
    setPageNumber(value){
      this.pageNum = value;
      this.offset = this.pageNum * this.limit - this.limit;
      this.searchPlaces();
    },
    async next() {
      this.offset = this.pageNum * this.limit;
      this.pageNum++
      await this.searchPlaces();
    },
    async back() {
      this.pageNum--
      this.offset = this.pageNum * this.limit - this.limit;
      await this.searchPlaces();
      this.showNext = true;
    },
  },
  async created() {
    await this.searchPlaces();
  },
});
