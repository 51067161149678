var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.error)?_c('div',[_c('div',{staticClass:"text-red-600"},[_vm._v(" "+_vm._s(_vm.error)+" ")])]):_vm._e(),(!_vm.error && _vm.roles.length > 0)?_c('div',[_c('table',[_c('tbody',[_vm._m(0),_vm._l((_vm.roles),function(id){return _c('tr',{key:id,staticClass:"hover:bg-gray-600"},[_c('td',{staticClass:"border text-white px-4 py-2"},[_vm._v(_vm._s(id.name))]),_c('td',{staticClass:"border text-white px-4 py-2"},[(id.place === null)?_c('span',[_vm._v("City Wide")]):_c('span',[_vm._v(_vm._s(id.place))])]),_c('td',{staticClass:"border text-red-500 px-4 py-2"},[(
          [id.name !== 'Admin' &&
          id.name !== 'Champion' &&
          id.name !== 'Devotee' &&
          id.name !== 'Advocate' &&
          id.name !== 'Supporter'] ||
          this.accessLevel.includes('admin'))?_c('span',[_c('button',{staticClass:"border text-white px-4 py-2 bg-red-900",on:{"click":function($event){_vm.showFireModal = true;
              _vm.fireId = id.id;
              _vm.firePlace = id.place_id;
              _vm.fireRoleName = id.name}}},[_vm._v("Fire")])]):_vm._e()])])})],2)])]):_vm._e(),(_vm.showFireModal)?_c('div',[_c('div',{staticClass:"fixed inset-0 z-50 flex justify-center items-center"},[_c('div',{staticClass:"flex flex-col w-2/6 max-w-5xl rounded-lg shadow-lg bg-red-300 text-red-800"},[_c('div',{staticClass:"p-5"},[_c('div',{staticClass:"flex justify-between items-start"},[_c('h3',{staticClass:"text-2xl font-semibold"},[_vm._v("Terminate User?")]),_c('button',{staticClass:"p-1 leading-none",on:{"click":function($event){_vm.showFireModal = false}}},[_vm._m(1)])])]),_vm._m(2),_c('div',{staticClass:" p-6 flex justify-end items-center"},[_c('button',{staticClass:"btn pr-1",on:{"click":function($event){_vm.showFireModal = false}}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn",on:{"click":_vm.fireUser}},[_vm._v("Confirm")])])])]),_c('div',{staticClass:"opacity-50 fixed inset-0 z-60 bg-black"})]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"px-4 py-2 font-bold text-center"},[_vm._v("Role")]),_c('td',{staticClass:"px-4 py-2 font-bold text-center"},[_vm._v("Place")]),_c('td')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-xl font-semibold h-6 w-6"},[_c('span',[_vm._v("x")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-6"},[_c('p',[_vm._v("Are you sure you want to terminate this user?")])])}]

export { render, staticRenderFns }