import { render, staticRenderFns } from "./FireRoles.vue?vue&type=template&id=16048b4a&"
import script from "./FireRoles.vue?vue&type=script&lang=js&"
export * from "./FireRoles.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports