



































import Vue from "vue";

export default Vue.extend({
  name: "UserSubMenu",
  props: ["accessLevel"],
  computed: {
    isUserDetailRoute(): boolean {
      return this.$route.name === "UserView" || this.$route.name === "UserEdit";
    },
    isUserBanRoute(): boolean {
      return this.$route.name === "UserBanHistory" || this.$route.name === "UserBanAdd";
    },
    isUserRoleRoute(): boolean {
      return this.$route.name === "UserHireRoles" || this.$route.name === "UserCurrentRoles" || this.$route.name === "UserFireRoles";
    },
  },
});
