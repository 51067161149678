
















































import Vue from "vue";
import CitizenOnlineModal from './CitizenOnlineModal.vue';
import Modal from './Modal.vue';
import ModalMixin from './mixins/ModalMixin';
import ModalService from "./services/ModalService.vue";

export default Vue.extend({
  name: "ConfirmAlertModal",
  components: {Modal},
  data: () => {
    return {
      addUsername: false,
      user: null,
      chat: false,
      messageboard: false,
      inbox: false,
      home: false,
      other: false,
    };
  },
  methods: {
    action(response){
      if(![this.chat, this.messageboard, this.inbox, this.home].includes(true)){
        this.other = true;
      }
      ModalService.open(CitizenOnlineModal, {
        action: response,
        details: {
          user: this.user,
          chat: this.chat,
          messageboard: this.messageboard,
          inbox: this.inbox,
          home: this.home,
          other: this.other
        }
      });
    },
  },
  mounted(){

  },
  mixins: [ModalMixin],
});
