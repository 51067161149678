







































import Vue from "vue";

export default Vue.extend({
  name: "BlockTools",
  data: () => {
    return {
      canAdmin: false,
      loaded: false,
    };
  },
  methods: {
    async checkAdmin() {
      try {
        const adminCheck = await this.$http.get(
          `/block/${  this.$store.data.place.block.id  }/can_admin`,
        );
        this.canAdmin = adminCheck;
      } catch (e) {
        console.log(e);
      }
    },
    async opener(link) {
      window.open(link, "targetWindow", "height=650,width=800,menubar=no,status=no");
    },
  },
  mounted() {
    this.checkAdmin();
  },
  watch: {
    "$store.data.place.block": {
      handler() {
        if (this.$store.data.place.block) {
          this.loaded = true;
          this.checkAdmin();
        }
      },
    },
  },
});
