














import Vue from "vue";
import CitizenOnlineModal from './CitizenOnlineModal.vue';
import Modal from './Modal.vue';
import ModalMixin from './mixins/ModalMixin';
import ModalService from "./services/ModalService.vue";

export default Vue.extend({
  name: "MyMessagesModal",
  components: {Modal},
  data: () => {
    return {
      
    };
  },
  methods: {
    openCitizenOnlineList(){
      ModalService.open(CitizenOnlineModal);
    },
  },
  mounted(){

  },
  mixins: [ModalMixin],
});
