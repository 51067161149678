




























import Vue from "vue";
import CitizenOnlineModal from './CitizenOnlineModal.vue';
import Modal from './Modal.vue';
import InstantMessageModal from './InstantMessageModal.vue';
import ModalMixin from './mixins/ModalMixin';
import ModalService from "./services/ModalService.vue";

export default Vue.extend({
  name: "MemberModal",
  components: {Modal},
  props: ["user", "status"],
  data: () => {
    return {
      messageSent: ""
    };
  },
  methods: {
    openCitizenOnlineList(){
      ModalService.open(CitizenOnlineModal);
    },
    sendMessage(){
      ModalService.open(InstantMessageModal, {
        user:this.user
      });
    },
  },
  mounted(){
    if(this.status){
      this.messageSent = "Message has been sent!";
      setTimeout(()=>{
        this.messageSent = "";
      }, 2000);
    }
  },
  mixins: [ModalMixin],
});
