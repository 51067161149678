



































import Vue from "vue";
export default Vue.extend({
  name: "UserHireRoles",
  data() {
    return {
      roleSelector: "first",
      error: null,
      roles: [],
      success: null,
    };
  },
  props: ["accessLevel"],
  methods: {
    async hireRole(): Promise<void> {
      if (this.roleSelector === "first") {
        this.error = "Please select a role";
        return;
      }
      try {
        await this.$http.post("/admin/hirerole", {
          member_id: this.$route.params.id,
          role_id: this.roleSelector,
        });
        // Handle success here
        this.error = null;
        this.success = "Hiring was successful";
      } catch (error) {
        // Handle error here
        this.success = null;
        this.error = "An error occurred";
      }
    },
    async getRoleList(): Promise<void> {
      try {
        const response = await this.$http.get("/admin/rolelist/");
        response.data.roles.forEach(role => {
          if (
            (role.name === "Champion" ||
              role.name === "Devotee" ||
              role.name === "Advocate" ||
              role.name === "Supporter" ||
              role.name === "Admin") &&
              !this.accessLevel.includes("admin")
          ) {
            return;
          } else {
            this.roles.push(role);
          }
        });
      } catch (e) {
        this.error = e;
      }
    },
  },
  mounted() {
    if (!this.accessLevel.includes("mayor")) {
      this.$router.push({ name: "restrictedaccess" });
    }
    this.getRoleList();
  },
});
