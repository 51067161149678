































































import Vue from "vue";

import Modal from './Modal.vue';
import ModalMixin from './mixins/ModalMixin';
import ModalService from "./services/ModalService.vue";

export default Vue.extend({
  name: "SecurityAlertModal",
  components: {Modal},
  props: [
    "data"
  ],
  data: () => {
    return {
      placeOwner: null,
    };
  },
  methods: {
    async getHomeOwner(){
      const username = await this.$http.get(`/member/info/${this.data.place_owner}`);
      this.placeOwner = username.data.memberInfo.username;
    },
  },
  mounted(){
    if(this.data.place_type === 'home'){
      this.getHomeOwner();
    }
  },
  mixins: [ModalMixin],
});
