









































import Vue from "vue";
export default Vue.extend({
  name: "InfoView",
  data() {
    return {
      info: null,
    };
  },
  props: ["accessLevel"],
  methods:{
    async getinfo(): Promise <void>{
      await this.$http.get(`/member/info/${this.$route.params.id}`)
        .then((response) => {
          this.info = response.data.memberInfo;
        });
    },
  },
  async mounted() {
    await this.getinfo();
  },
});
