





























































































import Vue from "vue";
import ConfirmAlertModal from './ConfirmAlertModal.vue';
import Modal from './Modal.vue';
import ConfigureModal from './ConfigureModal.vue';
import MyMessagesModal from './MyMessagesModal.vue';
import MemberModal from './MemberModal.vue';
import ModalMixin from './mixins/ModalMixin';
import ModalService from "./services/ModalService.vue";

export default Vue.extend({
  name: "CitizenOnlineModal",
  components: {Modal},
  props: ["action", "details"],
  data: () => {
    return {
      users: [],
      security: [],
      jailId: null,
    };
  },
  methods: {
    async getOnlineMembers(){
      const onlineUsers = await this.$http.get("/member/online_users");
      this.users = onlineUsers.data.returnUsers;
      this.users.forEach((user) => {
        if(user.security){
          this.security.push(user);
        }
      })
    },
    async getJailId(){
      const jail = await this.$http.get("/place/jail");
      this.jailId = jail.data.place.id;
    },
    confirmSecurityAlert(){
      ModalService.open(ConfirmAlertModal);
    },
    openConfigure(){
      ModalService.open(ConfigureModal);
    },
    openMyMessages(){
      ModalService.open(MyMessagesModal);
    },
    openMemberProfile(user){
      ModalService.open(MemberModal, {
        user: user
      });
    },
    alertSecurity(){
      let placeName = this.$store.data.place.name;
      let placeId = this.$store.data.place.id;
      let placeSlug = this.$store.data.place.slug;
      let placeType = this.$store.data.place.type;
      let placeOwner = this.$store.data.place.member_id;
      let user = this.$store.data.user.username;

      this.$socket.emit('security-alert', {
        user_name: user,
        place_name: placeName,
        place_id: placeId,
        place_slug: placeSlug,
        place_type: placeType,
        place_owner: placeOwner,
        alert_details: this.details,
      });
    },
    refresh(){
      this.users = [];
      this.security = [];
      this.getOnlineMembers();
    },
    openMessages(){
      // TO DO
      // Open last 25 messages received
    },
    configure(){
      // TO DO
      // Have options to add people to a friends list
      // Have option to be hidden/invisible
    },
    async opener(link) {
      window.open(link, "targetWindow", "height=650,width=800,menubar=no,status=no");
    },
  },
  created(){
    this.getOnlineMembers();
    this.getJailId();
  },
  mounted(){
    if(this.action){
      this.alertSecurity();
    }
  },
  mixins: [ModalMixin],
});
