


































import Vue from "vue";

export default Vue.extend({
  name: "Modal",
  components: {},
  methods: {
    closeModal() : void {
      this.$emit("close");
    },
  },
  mounted() {},
});
